<template>
  <div>
    <b-row>
      <b-col sm="8" xs="12">
        <h1>Roller</h1>
      </b-col>
      <b-col sm="4" xs="12" class="text-right" style="margin: auto">
        <b-button variant="primary" :to="{ name: 'admin-manage-role-save' }">
          Lägg till
        </b-button>
      </b-col>
      <b-col md="7" sm="12" class="my-1">
        <b-form-group
          label-for="filter-input"
          label-align-sm="right"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Filtrera...."
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Rensa
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="5" class="my-1">
        <b-form-group
          label="Per sida"
          label-for="per-page-select"
          label-cols-sm="6"
          label-align-sm="right"
          class="mb-0"
        >
          <PaginationPageSizeSelect id="per-page-select" v-model="perPage" />
        </b-form-group>
      </b-col>

      <b-col v-show="totalRows > perPage" cols="12" class="my-1 text-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          class="my-0"
        />
      </b-col>
    </b-row>

    <!--TABLE-->
    <b-table
      :items="roles"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      stacked="sm"
      show-empty
      small
      @filtered="onFiltered"
      @context-changed="onContextChanged"
    >
      <template #cell(fullName)="row">
        {{ row.item.fullName }}
      </template>
      <template #cell(email)="row">
        {{ row.item.email }}
      </template>
      <template #cell(actions)="row">
        <!-- Open sidebar. -->
        <font-awesome-icon
          class="d-none d-sm-block text-secondary"
          icon="pen-square"
          size="lg"
          role="button"
          @click="
            selected = row.item;
            isMoreInformationOpen = true;
          "
        />

        <b-button
          class="text-center d-block d-sm-none"
          block
          @click="
            selected = row.item;
            isMoreInformationOpen = true;
          "
        >
          Mer
        </b-button>
        <!-- ./ Open sidebar. -->
      </template>
      <template #empty="">
        <h4 class="text-center">Inga resultat</h4>
      </template>
      <template #emptyfiltered="">
        <div class="text-center">
          <h4>Inga resultat</h4>
          <h6>
            <a href="#" @click="filter = ''">
              Klicka här för att rensa aktiv filtrering
            </a>
          </h6>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center mt-4">
          <font-awesome-icon
            class="align-middle"
            icon="spinner"
            size="2x"
            spin
          />
        </div>
      </template>
    </b-table>

    <!--SIDEBAR-->
    <GenericSidebar
      :visible.sync="isMoreInformationOpen"
      :fields="[{ key: 'name', label: 'Namn' }]"
      :item="selected"
    />
  </div>
</template>

<script>
import { get } from '@/helpers/api';
import GenericSidebar from '@/components/GenericSidebar';
import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';

export default {
  name: 'ListRole',
  components: {
    GenericSidebar,
    PaginationPageSizeSelect
  },
  data() {
    return {
      selected: null,
      isMoreInformationOpen: false,
      roles: [],
      fields: [
        { key: 'name', label: 'Namn', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      isBusy: true
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields.
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    }
  },
  async mounted() {
    // Fetch data.
    await get('Role', 'GetAll')
      .then((x) => {
        this.roles = x.data;

        // Set the initial number of items.
        this.totalRows = this.roles.length;

        this.isBusy = false;
      })
      .catch((x) => {
        // error handling.
      });
  },
  methods: {
    onContextChanged(ctx) {
      if (ctx.perPage > this.totalRows)
        this.currentPage = 1;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    }
  }
};
</script>
